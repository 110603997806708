import { mapState } from 'vuex'

export default {
  data() {
    return {
      vLazyProductImage: {
        selector: 'img',
        error: require('@/assets/illustration/default_image_product.svg'),
        loading: require('@/assets/illustration/default_image_product.svg')
      }
    }
  },
  computed: {
    ...mapState('product', ['productVariant','variant'])
  },
  methods: {
    setToQuery (query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    defaultImageProduct(event){
      event.target.src = require('@/assets/illustration/default_image_product.svg')
    },

    imageProduct(product){
      if (product.type === "Simple") {
        return product.images[0].url;
      } else {
        let image = product.variants.filter((v) => {
          if(v.main === true){
            return v
          }
        })
        return image[0].images[0].url
      }
    },

    imageUser(image, gender){
      if (image) return image
      else {
        if (gender === 'male') {
          return require('@/assets/illustration/empty-profile-male.svg')
        } else {
          return require('@/assets/illustration/empty-profile-female.svg')
        }
      }
    },

    stockProduct(variant, id){
      let stock = ''
      variant.map((v) => {
        if(v.id === id){
          return stock = v.stock
        }
      })
      return stock
    },

    formatRupiah (angka, prefix) {
      var number_string = angka.toString().replace(/[^,\d]/g, '')
      var split = number_string.split('.')
      var sisa = Number(split[0]).toString().length % 3
      var rupiah = Number(split[0])
        .toString()
        .substr(0, sisa)
      var ribuan = Number(split[0])
        .toString()
        .substr(sisa)
        .match(/\d{3}/gi)

        if (split.length === 2) {
          if(split[1] >= 5) {
            if (ribuan[1].charAt(0) === '0') {
              ribuan[1] =  '0' + (parseInt(ribuan[1]) + 1)
            } else {
              ribuan[1] =  parseInt(ribuan[1]) + 1
            }
          }
        }
      var separator = ''

      if (ribuan) {
        separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah
      return prefix == undefined ? rupiah : rupiah ? 'Rp' + rupiah : ''
    },
    unformatdRupiah(number = '0') {
      
      return number.toString().replace(/\D/g, "")
    }



  }
}